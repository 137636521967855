<template>
  <v-row>
    <v-col>
      <v-card class="mt-2">
        <v-row class="ma-0 pa-0">
          <v-col>
            <v-btn
              class="mt-2"
              color="primary"
              style="margin-bottom: 0;padding-bottom: 0"
              @click="editInvoice(1)"
            >
              + DODAJ FAKTURĘ
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            md="3"
            style="margin-bottom: 0;padding-bottom: 0"
          >
            <v-text-field
              v-model="search"
              :prepend-inner-icon="icons.mdiMagnify"
              class="mt-2"
              clearable
              dense
              outlined
              placeholder="Wyszukaj..."
              style="margin-bottom: 0;padding-bottom: 0"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-data-table
        fixed-header
        item-key="id"
        :items="invoiceList"
        :headers="helpers.invoicesHeaders"
        no-results-text="Nie znaleziono faktur"
        class="table-rounded"
        hide-default-footer
        height="65vh"
        no-data-text="Nie znaleziono faktur"
      >
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiClose,
  mdiDotsVertical, mdiExportVariant, mdiEye,
  mdiFileCheckOutline, mdiLaptop,
  mdiMagnify,
  mdiPencilOutline,
  mdiSquareEditOutline
} from '@mdi/js'
import helpers from '@/utils/helpers'
import moment from "moment";

export default {
  name: 'Invoices',
  data() {
    return {
      helpers,
      icons: {
        mdiFileCheckOutline,
        mdiMagnify,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPencilOutline,
        mdiExportVariant,
        mdiLaptop,
        mdiEye,
        mdiClose
      },
      search: '',
      invoiceList: [
        {
          id: 1,
          fullNumber: 'FVZ_2022_10_001',
          sourceDocument: 'FVS 1/2022',
          sellerName: 'ONEY',
          receiptDate: new Date(),
          sellDate: new Date(),
          paymentDate: new Date(),
          creationDate: new Date(),
          totalNet: 1,
          totalVAT: 0.23,
          totalGross: 1.23,
          statusId: 0
        },
        {
          id: 2,
          fullNumber: 'FVZ_2022_10_002',
          sourceDocument: 'FVS 2/2022',
          sellerName: 'ONEY',
          receiptDate: new Date(),
          sellDate: new Date(),
          paymentDate: new Date(),
          creationDate: new Date(),
          totalNet: 2,
          totalVAT: 0.46,
          totalGross: 2.46,
          statusId: 0
        }
      ]
    }
  },
  mounted() {
    this.refreshDocuments()
  },
  methods: {
    refreshDocuments() {
      this.invoiceList.forEach((item) => {
        item.receiptDate = moment(item.receiptDate).format('DD-MM-YYYY')
        item.sellDate = moment(item.receiptDate).format('DD-MM-YYYY')
        item.paymentDate = moment(item.receiptDate).format('DD-MM-YYYY')
        item.creationDate = moment(item.receiptDate).format('DD-MM-YYYY')
      })
    },
    editInvoice(id) {
      if (id) {
        this.$router.push({name: 'add_invoice'})
      } else {
        this.$router.push({name: 'add_invoice'})
      }
    }
  }
}
</script>

<style scoped>

</style>
